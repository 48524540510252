<template>
  <div class="subscription">
    <Header />
    <b-container class="subscription-container">
      <b-skeleton-wrapper :loading="false">
        <b-row class="justify-content-md-start back-nav">
          <div class="col-lg-12 col-md-6">
            <b-link :to="'/subscription-fbonds-detail/' +
              product.bond_id +
              '/' +
              product.bond_type
              "><b-icon class="mx-2" icon="chevron-left"></b-icon><span>Kembali</span></b-link>
          </div>
        </b-row>

        <b-row class="justify-content-md-center mt-3 back-nav">
          <b-card class="subscription-form">
            <b-form>
              <div class="fund-name">
                <b-row>
                  <b-col>
                    <h1 class="text-center">Kalkulator Obligasi</h1>
                  </b-col>
                </b-row>
              </div>
              <b-container size="md" class="subscription-input-nominal mt-4">
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Data Bond</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.bond_name" disabled class=""></b-input>
                      </b-col>
                      <b-col> </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Underlying</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.bond_id" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">12</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Penerbit</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.bond_type === 1 ? 'Government' : 'Corporate'
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">12</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Frekuensi Kupon Pertahun</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.coupon_frequency" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">Pertahun</p> -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Fraction</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="formatter(
                          backTransaformFormatter(data?.fractional_value)
                        )
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">IDR</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>
                      Nominal
                      {{ product.bond_type == 1 ? "Pembelian" : "Penjualan" }}
                      Bond
                    </p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10" role="group">
                        <b-input id="nominalInputForm" :formatter="formatter"
                          :state="formStateNominal === null ? null : false" type="text" v-model="nominal"
                          class=""></b-input>
                        <b-form-invalid-feedback id="nominalInputForm">{{
                          formStateTextNominal
                        }}</b-form-invalid-feedback>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">IDR</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Tanggal Jatuh Tempo</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="formatDate(data?.maturity_date)" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Pembayaran Kupon Terakhir</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.last_coupon_date ? formatDate(data?.last_coupon_date) : 0" disabled
                          class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Pembayaran Kupon Selanjutnya</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="formatDate(data?.next_coupon_date)" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">12</p> -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Coupon (% p.a) gross</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="data?.coupon_rate" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">%</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>
                      Tanggal
                      {{ product.bond_type == 1 ? "Pembelian" : "Penjualan" }}
                    </p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="formatDate(undefined)" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Tanggal Setelmen</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="dateSatelmen" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>
                      Harga
                      {{ product.bond_type == 1 ? "Pembelian" : "Penjualan" }}
                    </p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10" role="group">
                        <b-input id="hargaPembelianInput" :disabled="disabledHargaPembelian" :formatter="formatter"
                          :state="formStateHargaPembelian === null ? null : false
                            " type="text" v-model="hargaPembelian" class=""></b-input>
                        <b-form-invalid-feedback id="hargaPembelianInput">{{
                          formStateTextHargaPembelian
                        }}</b-form-invalid-feedback>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Current Yield</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="currentYield" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">%</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <template v-if="portfolio && product.bond_type == 2">
                  <b-row align-v="center" class="mt-3">
                    <b-col>
                      <p>Harga Perolehan</p>
                    </b-col>
                    <b-col>
                      <b-row align-v="center">
                        <b-col cols="10">
                          <b-input :value="!portfolio?.acq_price
                            ? '-'
                            : formatter(portfolio?.acq_price)
                            " disabled class=""></b-input>
                        </b-col>
                        <b-col>
                          <p class="additionalInformation">%</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row align-v="center" class="mt-3">
                    <b-col>
                      <p>Tanggal Perolehan</p>
                    </b-col>
                    <b-col>
                      <b-row align-v="center">
                        <b-col cols="10">
                          <b-input :value="formatDate(acqDate)" disabled class=""></b-input>
                        </b-col>
                        <b-col>
                          <p class="additionalInformation">%</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <!-- <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Yield To Maturnity</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="yieldToMaturity" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">%</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> -->
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Hari Bunga Berjalan <b>(Accured Interest Day)</b><span class="starRed">*</span></p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="hariBungaBerjalan" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Nominal Pokok <span class="starRed">*</span></p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input disabled :value="nominalPokok" class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">IDR</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>Accrued Interest <span class="starRed">*</span></p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="accuratedInterest > 0
                          ? formatter(accuratedInterest)
                          : accuratedInterest
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="product.bond_type === 2" align-v="center" class="mt-3">
                  <b-col>
                    <p>Total Nominal Hasil Penjualan (Gross)</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="totalNominalHasilPenjualanGross > 0
                          ? formatter(totalNominalHasilPenjualanGross)
                          : totalNominalHasilPenjualanGross
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="product.bond_type == 1" align-v="center" class="mt-3">
                  <b-col>
                    <p>Bea Materai</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="additionalCost > 0
                          ? formatter(additionalCost)
                          : additionalCost
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="product.bond_type === 2" align-v="center" class="mt-3">
                  <b-col>
                    <p>Capital Gain/Loss</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="capitalGainOrGros > 0
                          ? formatter(capitalGainOrGros)
                          : capitalGainOrGros
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="product.bond_type === 2" align-v="center" class="mt-3">
                  <b-col>
                    <p>Pajak Accrued Interest (Based on Holding Period)</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="pajakAccurated > 0
                          ? formatter(pajakAccurated)
                          : pajakAccurated
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="product.bond_type === 2" align-v="center" class="mt-3">
                  <b-col>
                    <p>Total Pajak (10%)</p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="totalPajak10Percent > 0
                          ? formatter(totalPajak10Percent)
                          : totalPajak10Percent
                          " disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <!-- <p class="additionalInformation">IDR</p>   -->
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row align-v="center" class="mt-3">
                  <b-col>
                    <p>
                      Total Nominal
                      {{ product.bond_type == 1 ? "Pembelian" : "Penjualan" }}
                      <span class="starRed">*</span>
                    </p>
                  </b-col>
                  <b-col>
                    <b-row align-v="center">
                      <b-col cols="10">
                        <b-input :value="totalNominal" disabled class=""></b-input>
                      </b-col>
                      <b-col>
                        <p class="additionalInformation">IDR</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row align-v="center" class="mt-5">
                  <b-col>
                    <p><b>Keterangan</b></p>
                    <p><span class="starRed">*</span> Harga Estimasi </p>
                  </b-col>
                </b-row>

                <b-button block :disabled="formStateNominal !== null ||
                  formStateHargaPembelian !== null ||
                  disabledButtonWhenNotFbonds ||
                  (product.bond_type == 1 && !data?.is_buy) ||
                  (product.bond_type == 2 && !data?.is_sell)
                  " class="mt-5 btn-tertiary py-2" @click="checkData()">Lanjut</b-button>
              </b-container>
            </b-form>
          </b-card>
        </b-row>
      </b-skeleton-wrapper>
    </b-container>
    <div class="modal-portfolio-detail">
      <b-modal id="checkModal" ref="checkModal" size="lg" class="modal-failed-register" centered hide-footer hide-header
        no-close-on-backdrop>
        <div class="d-block">
          <h1 class="my-2 text-dark">Peringatan</h1>
          <hr />
          <div class="px-4">
            <ul>
              <li>
                Transaksi di pasar sekunder tergantung ketersediaan barang di PT. CGS International Sekuritas
                </li>
              <li>
                Harga ini adalah estimasi, untuk nominal yang sesuai adalah berasal dari Trade Confirmation yang akan
                dikirimkan via email anda.</li>
              <li>
                Transaksi di pasar sekunder masih akan berubah tergantung harga yang ada di Market bonds
              </li>
              <li>
                Harga yang disubmit masih dapat berubah sesuai dengan harga yang ada di market
              </li>
            </ul>
          </div>
          <b-row class="justify-content-md-end btn-submit-row mb-4">
            <div class="col-lg-12 col-md-6">
              <b-button size="sm" class="btn-outline-tertiary w-50 px-4 py-2" @click="closeCheckData">Tidak
                setuju</b-button>
              <b-button size="sm" class="btn-outline-tertiary px-4 py-2 w-50" @click="next_buy()">Setuju</b-button>
            </div>
          </b-row>
        </div>
      </b-modal>
    </div>
    <Footer />
    <ModalMessage :open="!!openModalMessage" :closeHandler="closeModalMessage" title="Terjadi Kesalahan"
      :bodyText="openModalMessage || ''" />
    <ModalNotRegister :isObligasi="true" :open="isModalNotRegisterOpen" :closeHandler="closeHandlerModalNotRegister" />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import momentBussiness from "moment-business-days";
import ModalMessage from "@/components/partials/modal/message.vue";
import moment from "moment";
import ModalNotRegister from "@/components/partials/modal/subscription/notRegister.vue";
import {formatter, backFormatter, backTransaformFormatter} from '@/utils'

export default {
  name: "SubscriptionCalculatorObligasi",
  metaInfo: {
    title: "Calculator | CGS iTrade Fund",
  },
  data() {
    return {
      product: {
        bond_id: "1",
        bond_type: 1,
      },
      materai: 10000,
      nominal: "1",
      minimalNominal: 0,
      hargaPembelian: "1",
      minimalHargaPembelian: 0,
      data: null,

      // Modal Message
      openModalMessage: false,
      disabledButtonWhenNotFbonds: false,
      isModalNotRegisterOpen: false,
      // Coupon
      portfolio: null,
    };
  },
  watch: {
    disabledHargaPembelian(val) {
      if (val) {
        this.hargaPembelian = this.formatter(
          this.backFormatter(this.hargaProduk)
        );
      }
    },
  },
  mounted() {
    this.product = {
      bond_id: this.$route.params.bond_id,
      bond_type: parseInt(this.$route.params.bond_type),
      product_id: this.$route.params.product_id,
    };

    if (!this.user.individual?.is_fbonds) {
      this.isModalNotRegisterOpen = true;
      this.disabledButtonWhenNotFbonds = true;
    }

    if (this.product.bond_type == 2 && !this.$route.params.product_id) {
      this.$router.push("/portfolio");
      return;
    }

    const providersPromise = [this.fetchFund()];

    // Check Local Stroage khusus jual
    const sellObligasiItem = localStorage.getItem("sellObligasiItem");

    if (this.product.bond_type == 2 && !sellObligasiItem) {
      this.$router.push("/portfolio");
      return;
    }

    if (sellObligasiItem && this.product.bond_type == 2) {
      // localStorage.setItem('sellObligasiItem', JSON.stringify({"stock":"FR0067","acq_price":101,"volume":100000000,"acq_date":"19/12/2023","available":100000000}));
      const sellObligasiItemParse = JSON.parse(sellObligasiItem);
      if (sellObligasiItemParse.stock != this.product.bond_id) {
        this.$router.push("/portfolio");
        return;
      }

      this.portfolio = sellObligasiItemParse;
    }

    Promise.all(providersPromise)
      .then(() => {
        this.nominal = this.formatter(
          this.backTransaformFormatter(this.data.min_buy)
        );
        this.minimalNominal = parseFloat(this.data.min_buy);
        this.hargaPembelian = this.formatter(
          this.backFormatter(this.hargaProdukBackFormatter)
        );
        this.minimalHargaPembelian = parseFloat(this.data.min_negotiable);
        this.openModalMessage = false;

        if (!this.data.is_sell && this.product.bond_type == 2) {
          this.openModalMessage = `Produk tidak dapat dijual`;
        } else if (!this.data.is_buy && this.product.bond_type == 1) {
          this.openModalMessage = `Produk tidak dapat dibeli`;
        }
      })
      .catch((err) => {
        this.openModalMessage = `Terjadi kesalahan didalam aplikasi. Kesalahan: ${err?.message || "Terjadi Kesalahan"
          }`;
        console.log(err);
      });
  },
  components: {
    Header,
    Footer,
    ModalMessage,
    ModalNotRegister,
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      app_url: "app_url",
      user: "auth/user"
    }),
    nominalFormatter() {
      const nominalBack = this.backFormatter(this.nominal);
      console.log('Nominal Back', nominalBack)
      return nominalBack;
    },
    formStateNominal() {
      const fractionalValue = parseFloat(this?.data?.fractional_value);
      if (isNaN(this.nominalFormatter)) return "NOT_EXIST";
      // else if (this.nominalFormatter <= 0) return 'NOT_ACHIVE_MINIMAL'
      else if (this.nominalFormatter < this.minimalNominal)
        return "NOT_ACHIVE_MINIMAL";
      else if (!isNaN(fractionalValue) && (this.nominalFormatter % fractionalValue) !== 0)
        return "NOT_ACTIVE_MODULES";
      else return null;
    },
    formStateTextNominal() {
      switch (this.formStateNominal) {
        case "NOT_ACHIVE_MINIMAL": {
          return `Minimal Pembelian adalah ${this.formatter(
            this.backTransaformFormatter(this.minimalNominal)
          )}`;
        }
        // case 'NOT_ACHIVE_MINIMAL': {
        //   return `Minimal Pembelian adalah 0.1`
        // }
        case "NOT_EXIST": {
          return "Mohon masukan angka";
        }
        case "NOT_ACTIVE_MODULES": {
          return `Pembelian harus setiap kelipatan ${this.formatter(this.backTransaformFormatter(this?.data?.fractional_value || 0))}`;
        }
        default: {
          return null;
        }
      }
    },
    hargaPembelianFormatter() {
      return this.backFormatter(this.hargaPembelian);
    },
    formStateHargaPembelian() {
      if (isNaN(this.hargaPembelianFormatter)) return "NOT_EXIST";
      else if (this.hargaPembelianFormatter <= 0) return "NOT_ACHIVE_MINIMAL";
      // else if (this.hargaPembelianFormatter < this.minimalHargaPembelian) return 'NOT_ACHIVE_MINIMAL'
      else return null;
    },
    formStateTextHargaPembelian() {
      switch (this.formStateHargaPembelian) {
        case "NOT_ACHIVE_MINIMAL": {
          return `Minimal Pembelian adalah 0.1`;
        }
        case "NOT_EXIST": {
          return "Mohon masukan angka";
        }
        default: {
          return null;
        }
      }
    },
    disabledHargaPembelian() {
      return this.nominalFormatter < this.minimalHargaPembelian;
    },
    dateSatelmenRaw() {
      // return momentBussiness().businessAdd(1, "days");
      return momentBussiness().businessAdd(2, "days");
    },
    dateSatelmen() {
      return this.dateSatelmenRaw.format("DD MMMM YYYY");
    },
    nominalPokok() {
      return this.formatter(
        this.backTransaformFormatter(
          (this.nominalFormatter * this.hargaPembelianFormatter) / 100
        )
      );
    },
    hargaProduk() {
      return this?.data?.sell_price;
    },
    hargaProdukBackFormatter() {
      return parseFloat(this.hargaProduk);
    },
    currentYield() {
      const result =
        (parseFloat(this.data?.coupon_rate) /
          parseFloat(this.hargaPembelianFormatter)) *
        100;
      
      const value = this.formatter(result.toFixed(2));

      console.log('Current Yield', {
        counpontRate: this.data?.coupon_rate,
        hargaPembelianFormatter: this.hargaPembelianFormatter,
        result, value
      })

      return value;
    },
    yieldToMaturity() {
      // const maturity = Math.round(moment(this.data?.maturity_date).diff(moment(), 'years', true));
      // const result = (parseFloat(this.data?.coupon_rate) + (100 - this.hargaProdukBackFormatter) / maturity) / ((100 + this.hargaProdukBackFormatter) / 2) * 100;
      // return this.formatter(this.backTransaformFormatter(result.toFixed(2)));
      const yieldValue =
        ((this.backFormatter(this.totalNominal) +
          parseFloat(this.data?.coupon_rate) * 100) /
          this.hargaProdukBackFormatter -
          1) *
        100;
      return yieldValue;
    },
    hariBungaBerjalan() {
      const issueLast = this?.data?.last_coupon_date ? this?.data?.last_coupon_date : this?.data?.issue_date;
      const result = Math.floor(
        moment(this.dateSatelmenRaw).diff(
          issueLast,
          "days",
          true
        )
      );
      return result < 0 ? 0 : result;
    },
    accuratedInterest() {
      const issueLast = this?.data?.last_coupon_date ? this?.data?.last_coupon_date : this?.data?.issue_date;
      const sentelment = Math?.ceil(
        moment(this.dateSatelmenRaw)?.diff(
          moment(issueLast),
          "days"
        )
      );
      if (this?.data?.bond_type == 2) {
        const couponRate = parseFloat(this?.data?.coupon_rate) / 100;
        const hasil = (this.nominalFormatter * couponRate * sentelment) / 360;
        return isFinite(hasil) ? hasil : 0;
      }

      const constantValue = 1000000;
      const kuponSelanjutnyaDanLast = Math?.ceil(
        moment(this.data?.next_coupon_date)?.diff(
          moment(issueLast),
          "days"
        )
      );

      // const coupon = Math.round(((kuponSelanjutnyaDanLast * 2) * (parseFloat(this?.data?.coupon_rate) / 100)) * 100) / 100 ;
      // const result = ((constantValue * sentelment) / ((kuponSelanjutnyaDanLast * 2) * (parseFloat(this?.data?.coupon_rate) / 100))) * this.nominalFormatter / constantValue;
      const sentelmentMultipleConstant = constantValue * sentelment;
      const nextKuponAndLastMultipleDouble = kuponSelanjutnyaDanLast * 2;
      const couponRate = parseFloat(this.data?.coupon_rate) / 100;

      const result =
        ((sentelmentMultipleConstant / nextKuponAndLastMultipleDouble) *
          couponRate *
          this.nominalFormatter) /
        constantValue;

      return isFinite(result) ? result : 0;
    },
    totalNominal() {
      const pajak = this.capitalGainOrGros + this.totalPajak10Percent;
      console.log('gross', this.totalNominalHasilPenjualanGross)
      console.log('cost', this.additionalCost)
      return this.formatter(
          this.totalNominalHasilPenjualanGross +
          this.additionalCost -
          (this.product.bond_type == 2 ? pajak : 0)
      );
    },
    additionalCost() {
      return this?.nominalFormatter > 10000000 ? 10000 : 0;
    },
    capitalGainOrGros() {
      if (!this.portfolio) return 0;
      return (
        ((parseFloat(this.hargaProduk) - parseFloat(this.portfolio.acq_price)) /
          100) *
        parseFloat(this.portfolio.volume)
      );
    },
    pajakAccurated() {
      if (this.product.bond_type == 2) {
        return this.accuratedInterest * 10 / 100;
      }

      const couponRate = parseFloat(this?.data?.coupon_rate) / 100;
      const sentelmentAcqDate = this.dateSatelmenRaw.diff(this.acqDate, "days");
      const result1 = Math.round(
        ((1000000 * sentelmentAcqDate) / 368) * couponRate
      );
      const nominalBagiSejuta = this.nominalFormatter / 1000000;
      const result2 = nominalBagiSejuta * result1;

      return result2 / 0.1;
    },
    totalPajak10Percent() {
      return (this.capitalGainOrGros * 10) / 100 + this.pajakAccurated;
    },
    acqDate() {
      return moment(this.portfolio?.acq_date, "DD/MM/YYYY");
    },
    totalNominalHasilPenjualanGross() {
      console.log('this.nominalPokok', this.backFormatter(this.nominalPokok))
      console.log('this.accuratedInterest', this.accuratedInterest)
      return this.backFormatter(this.nominalPokok) + this.backFormatter(this.accuratedInterest);
    },
  },
  methods: {
    checkData() {
      if (this.$refs["checkModal"]) this.$refs["checkModal"].show();
    },
    closeCheckData() {
      if (this.$refs["checkModal"]) this.$refs["checkModal"].hide();
    },
    next_buy() {
      const biaya = this.backFormatter(this.nominalPokok) + this.backFormatter(this.formatter(this.accuratedInterest)) + this.materai;

      localStorage.setItem("invest_nominal", this.nominalFormatter);
      localStorage.setItem("total", this.backFormatter(this.totalNominal));
      localStorage.setItem("harga", this.hargaPembelianFormatter);
      localStorage.setItem(
        "accurated_interest",
        this.backFormatter(this.formatter(this.accuratedInterest))
      );
      localStorage.setItem("biaya", biaya);
      localStorage.setItem('nominal_pokok', this.backFormatter(this.nominalPokok))
      localStorage.setItem('materai', this.backFormatter(this.formatter('10000')));
      localStorage.setItem('nominal_total', this.backFormatter(this.formatter(this.totalNominal)));
      localStorage.setItem('biaya_penjualan', this.backFormatter(this.formatter('0')));
      localStorage.setItem('pajak', this.backFormatter(this.formatter('10000')) + this.backFormatter(this.formatter(this.accuratedInterest)));

      this.$router.push(
        `/subscription-fbonds-review/${this.product.bond_id}/${this.product.bond_type
        }${this.product.bond_type == 2 ? `/${this.product.product_id}` : ""}`
      );
    },
    async fetchFund() {
      // this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}fbonds/product?bond_id=${this.product.bond_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      // console.log(response)

      // const response = {
      //   data: {
      //     "meta": {
      //       "code": 200,
      //       "status": "success",
      //       "message": "Data successfully retrieved"
      //     },
      //     "data": [
      //       {
      //         "id": 30,
      //         "bond_id": "FR0095",
      //         "bond_name": "Obligasi Negara Republik Indonesia Seri FR0095",
      //         "bond_type": 1,
      //         "coupon_type": null,
      //         "coupon_rate": "6.375",
      //         "coupon_frequency": 2,
      //         "coupon_basis": null,
      //         "currency": "IDR",
      //         "isin": "IDG000020702",
      //         "close_price": "99.5",
      //         "rating": "AAA",
      //         "issue_date": "2022-08-15",
      //         "maturity_date": "2028-08-15",
      //         "first_coupon_date": "2023-02-15",
      //         "last_coupon_date": "2023-08-15",
      //         "proceed_value": "5000000000",
      //         "buy_price": "98.5",
      //         "sell_price": "100.3",
      //         "face_value": "5000000000",
      //         "fractional_value": "100000000",
      //         "fbond_status": 1,
      //         "fbond_images": null,
      //         "created_at": "2023-11-09T07:34:40.000000Z",
      //         "updated_at": "2023-12-07T02:27:19.000000Z",
      //         "min_buy": "100000000",
      //         "min_negotiable": "1000000000",
      //         "is_buy": 1,
      //         "is_sell": 1,
      //         "next_coupon_date": "2023-08-15"
      //       }
      //     ]
      //   }
      // }

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }

      this.data = response.data.data[0];
      //this.data = {
      // "id": 30,
      // "bond_id": "FR0095",
      // "bond_name": "Corporate Negara Republik Indonesia Seri FR0095",
      // "bond_type": 2,
      // "coupon_type": null,
      // "coupon_rate": "9.35",
      // "coupon_frequency": 4,
      // "coupon_basis": null,
      // "currency": "IDR",
      // "isin": "IDG000020702",
      // "close_price": "99.4",
      // "rating": "AAA",
      // "issue_date": "2022-08-15",
      // "maturity_date": "2035-06-15",
      // "first_coupon_date": "2023-02-15",
      // "last_coupon_date": "2023-10-05",
      // "proceed_value": "5000000000",
      // "buy_price": "100.2",
      // "sell_price": "100.2",
      // "face_value": "5000000000",
      // "fractional_value": "1000000",
      // "fbond_status": 1,
      // "fbond_images": null,
      // "created_at": "2023-11-09T07:34:40.000000Z",
      // "updated_at": "2023-12-11T03:38:37.000000Z",
      // "min_buy": "100000000",
      // "min_negotiable": "1000000000",
      // "is_buy": 1,
      // "is_sell": 1,
      // "next_coupon_date": "2024-01-06"
      // }

      // // Hard CODE FOR CALCULATE ACCURATED INTEREST
      // // PLEASE REMOVE LATER
      // this.data.next_coupon_date = '2024-02-15';
      // this.data.last_coupon_date = '2023-08-15';
      // this.data.coupon_rate = '8';
      // // this.loadingTime = 0;
    },
    closeModalMessage() {
      this.openModalMessage = false;
    },
    formatDate(value) {
      return moment(value).format("DD MMMM YYYY");
    },
    closeHandlerModalNotRegister() {
      this.isModalNotRegisterOpen = false;
    },
    formatter,
    backFormatter,
    backTransaformFormatter
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}

.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-form {
  padding: 20px 0;
  width: 100%;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.vs-con-input-label {
  width: 100%;
}

.additionalInformation {
  margin: auto;
}

.back-nav {
  padding: 0px 144px 0px 128px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

.subscription-input-nominal {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
  }

  p {
    color: #3d3d3d;
    /* Text M/Regular */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.btn-invest-nominal {
  font-family: $inter;
  background: white;
  border-radius: 2px;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #374151;
  width: 230px;
}

.btn-invest-nominal:hover {
  background: $tertiaryColor;
  color: white;
}

.btn-invest-nominal:focus {
  box-shadow: none !important;
}

.btn-invest-nominal:active {
  background: $tertiaryColor !important;
  outline: none !important;
  color: white !important;
}

.btn-tertiary:disabled {
  cursor: no-drop !important;
}

.header-modal {
  font-size: 32px;
}

@media only screen and (max-width: 768px) {
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }

  .back-nav {
    padding: 0px;
  }

  .btn-invest-nominal {
    width: 100%;
  }

  .header-modal {
    font-size: 16px !important;
  }
}


// Button

// .btn-outline-tertiary {
//   font-family: $inter;
//   background: white !important;
//   border-radius: 4px;
//   border-color: $tertiaryColor !important;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   border: solid 1px $tertiaryColor !important;
//   color: $tertiaryColor !important;
// }

// .btn-outline-tertiary:hover {
//   background: white;
// }

// .btn-outline-tertiary:focus {
//   background: white !important;
//   color: $tertiaryColor !important;
//   border: solid 1px $tertiaryColor !important;
//   box-shadow: none !important;
//   border-color: $tertiaryColor !important;
// }

// .btn-outline-tertiary:active {
//   background: white !important;
//   color: $tertiaryColor !important;
//   border: solid 1px $tertiaryColor !important;
//   outline: none !important;
//   border-color: $tertiaryColor !important;
// }
.starRed {
  color: red;
}
</style>
